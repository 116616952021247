//
// Base
//

// Body
body {
  background: $page-bg;
}

.loaderHolder {
  left: 0;
  top: 0;
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 125, 111, 0.23);
  z-index: 1310;
}

.loaderMain {
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1000;
  height: 31px;
  width: 31px;
}

.form-control.form-control-solid {
  background-color: #f2f3f4;
  border-color: #9caab6;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      padding-top: get($header-config, desktop, fixed, height) +
        get($subheader-config, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }

    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) +
        get($subheader-config, fixed, height-mobile);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}

.leaflet-container {
  width: 1000px;
  height: 400px;
}
.filter-label-custom {
  .filter-label {
    float: left !important;
    width: 100%;
  }
}
