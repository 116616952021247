//
// Forms
//

// Include Form Components
@import 'forms/base';

.role-form .form-group {
  margin-top: 7px;
  margin-bottom: 7px;
}
