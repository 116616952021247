.inner-class-div-sk .slick-slide {
  padding: 0 10px;
}
.slick-prev:before,
.slick-next:before {
  color: #0d0000;
}
.react-viewer-list > li {
  width: 150px !important;
  height: 100px !important;
}
.react-viewer-list > li > img {
  width: 150px !important;
  height: 100px !important;
}
.react-viewer-inline {
  min-height: 550px !important;
}
.selected-receipt {
  border: solid #1c396a;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: auto !important;
}

.react-viewer-fullscreen {
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  width: 55px;
  height: 55px;
  border-radius: 0 0 0 40px;
  cursor: pointer;
  z-index: 100;
}

.approvel-product {
  border-radius: 5px;
  border: 2px solid #559d07;
}
.unapproved-product {
  border-radius: 5px;
  border: 2px solid #9d0707;
}

.td-values {
  overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 200px;
}
