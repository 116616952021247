.graph-container {
  width: 100%;
  min-height: 350px;
  position: relative;
  min-width: 500px;
  overflow: hidden;
  background: url('../../../../_metronic/_assets/images/grid.gif');
  cursor: default;
}
.form-group {
  margin-top: 10px;
}
.leftToolBar {
  text-align: center;
  background-color: #1a1a27;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #3f3f7a;
  font-size: 1rem;
  padding: 5px 2px 5px 2px;
  margin-bottom: 0px;
}
.leftToolBar span {
  font-size: 0.8rem;
  margin: 0px;
}
.leftToolBarContainer {
  border: 1px solid rgb(200, 200, 245);
  width: 100%;
  height: auto;
  min-height: 90%;
  font-size: 0.8rem;
}
.objectToolshape {
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 5px;
}
.objectToolshapeDisable {
  background-color: #ccc;
  cursor: not-allowed;
}

.objectToolshape img {
  width: 30px;
  height: 30px;
}
.leftToolBarContainer table tr td,
.leftToolBarContainer table tr th,
.leftToolBarContainer button {
  font-size: 0.8rem;
}
