#dbqueryTextArea{
    font-size: 0.8rem;
}
.nav-tabs .nav-link.active{
    background: #4384e6;
    border-radius: 15px 15px 0px 0px;
    color:#fff;
}
#dbNodeTable tr{
    border-bottom: 1px solid #cccc;
    padding-top: 5px;
    padding-bottom: 5px;
}
#dbNodeTable tr .form-check {
    display: block;
    min-height: 0.7rem;
    padding-left: 1.5em;
    margin-bottom: .125rem;
}
.tab-content .form-control{
    font-size: 0.8rem !important;  
    padding: 0.1rem;
}
.tab-content table{
    margin-bottom: 0px;
}
.leftToolBarContainer table tr td {
    word-break: break-word;
}
.requiredfield{
    color: red;
    font-weight: bold;
}
.custombtn{
    font-size: 0.8rem;
    padding: 0.175rem 0.3rem;
    margin-top: 10px;
}
.custombtn .spinner-border-sm {
    width: 0.7rem;
    height: 0.7rem;
}
.notesdiv {
    margin-bottom: 15px;
    padding: 4px 12px;
  }
  
.notesdiv .danger {
    background-color: #ffdddd;
    border-left: 6px solid #f44336;
  }
  
.notesdiv .success {
    background-color: #ddffdd;
    border-left: 6px solid #04AA6D;
  }
  
.notesdiv .info {
    background-color: #e7f3fe;
    border-left: 6px solid #2196F3;
  }
  
  
.notesdiv .warning {
    background-color: #ffffcc;
    border-left: 6px solid #ffeb3b;
}
.customselect, .custominput{
    font-size: 0.8rem;
    padding: .275rem 0.25rem .275rem .23rem;
    line-height: 1.2;
    width: 100%;
}